<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增 人员</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>分类</b></label>
            <el-select v-model="model.TYPE" style="display: block">
              <el-option label="维修" value="FIX" />
              <el-option label="物料" value="MAT" />
            </el-select>
          </div>
          <div class="form-group">
            <label><b>教师</b></label>
            <el-select v-model="model.TEA_GUID" placeholder="请选择教师" style="display: block">
              <el-option :label="it.NAME" :value="it.GUID" v-for="it in ls_tea" :key="it.ID" />
            </el-select>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {},
      ls_tea:[],
    }
  },
  methods:{
    init(model){
      this.sta={show:true,loading:false}
      if (model==undefined || model==null) this.model={TYPE:"FIX"};
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/School/ORG/TeacherApi/GetList",
        completed(its){self.ls_tea=its;}
      })
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/MSG/NoticeApi/Save",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
